/*
 * smt-app v0.1
 * Author smt-app
 */

  #app {
    max-width: calc(1420px + 490px);
    margin: 0 auto;
  }

  body {
    background-color: var(--bg-dark-comb) !important;
  }
  
  body .page {
    position: relative;
  }
  
  body .page section {
    position: relative;
    padding: 40px 0 50px;
    overflow: hidden;
  }

  body .page section:not(:nth-child(3)){
    overflow: hidden;
  }
  
  body .page section.hero {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .navbar {
    font-size: 13px;
    font-weight: 600;
    color: var(--white) !important;
  }
  
  .navbar .logo-link {
    margin-right: 55%;
  }
  
  .navbar-nav .nav-link {
    padding-top: 0.715rem;
    padding-bottom: 0.715rem;
    cursor: pointer;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    white-space:nowrap
  }
  
  .navbar-nav.navbar-right li {
    margin-right: 15px;
  }
  
  .navbar-nav.navbar-right a {
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid var(--gray);
    padding: 10px 10px !important;
    color: var(--gray-light);
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
  }

  .navbar-nav.navbar-right a img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .navbar-nav.navbar-right a:hover {
    color: var(--yellow) !important;
  }
  
  .navbar-nav .nav-link:hover {
    color: var(--yellow) !important;
  }
  
  .navbar-brand {
    text-transform: none;
  }
  
  .navbar .logo {
    width: 115px;
  }
  
  .navbar .navbar-nav.collapse {
    display: flex;
  }

  .navbar .language-switcher a.lan-link {
    text-decoration: none;
    color: var(--gray-light);
    display: flex;
    align-items: center;
    border: 1px solid var(--gray);
    border-radius: 6px;
    padding: 10px;
  }

  .navbar .language-switcher a.lan-link img {
    width: 20px;
    height: 20ox;
    height: 20px;
    margin-right: 5px;
  }
  
  /* WAVE */
  .wave-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }
  
  .wave-shape .shape-fill {
    fill: #ffffff;
  }
  
  .wave-shape svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 100px;
  }
  
  /* /WAVE */
  
  .box-title {
    text-align: left;
    max-width: 50%;
  }
  
  .box-title h1 {
    min-width: 500px;
  }
  
  .subtitle {
    text-transform: uppercase;
    font-weight: 900;
    color: #aaafb5;
    font-size: 20px;
  }
  
  .msg-h1.title {
    text-transform: none;
    font-weight: 900;
    font-size: 3.3em;
    color: var(--dark);
    background: linear-gradient(
      267.91deg,
      var(--yellow) 9.35%,
      var(--yellow-light) 96.48%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .msg-h1.title,
  .marquee span,
  .button--arrow-and-text span {
    font-family: var(--font-family-secondary);
  }
  
  .fixed-bottom {
    padding-top: 20px;
  }
  
  section.hero .hero-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 90px 0 20px 0;
  }

  section.hero .hero-wrapper .triangle {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #4a4a4a;
    position: absolute;
    width: 1500px;
    height: 500px;
    top: -200px;
    transform: rotate(180deg);
    z-index: -1;
    background-image: linear-gradient(139deg, var(--bg-dark-comb) 0%, #000 100%);
  }

  section.about .waves {
    clip-path: polygon(100% 0%, 0% 0% , 0% 61.11%, 1% 60.97%, 2% 60.56%, 3% 59.89%, 4% 58.98%, 5% 57.84%, 6% 56.51%, 7% 55.02%, 8% 53.40%, 9% 51.70%, 10% 49.96%, 11% 48.21%, 12% 46.52%, 13% 44.90%, 14% 43.42%, 15% 42.10%, 16% 40.97%, 17% 40.07%, 18% 39.41%, 19% 39.01%, 20% 38.89%, 21% 39.04%, 22% 39.46%, 23% 40.15%, 24% 41.07%, 25% 42.22%, 26% 43.56%, 27% 45.06%, 28% 46.69%, 29% 48.39%, 30% 50.13%, 31% 51.87%, 32% 53.57%, 33% 55.17%, 34% 56.65%, 35% 57.97%, 36% 59.08%, 37% 59.97%, 38% 60.62%, 39% 61.00%, 40% 61.11%, 41% 60.94%, 42% 60.51%, 43% 59.81%, 44% 58.87%, 45% 57.71%, 46% 56.36%, 47% 54.86%, 48% 53.23%, 49% 51.52%, 50% 49.78%, 51% 48.04%, 52% 46.35%, 53% 44.75%, 54% 43.28%, 55% 41.97%, 56% 40.87%, 57% 39.99%, 58% 39.36%, 59% 38.99%, 60% 38.89%, 61% 39.07%, 62% 39.52%, 63% 40.23%, 64% 41.18%, 65% 42.35%, 66% 43.71%, 67% 45.22%, 68% 46.86%, 69% 48.57%, 70% 50.31%, 71% 52.05%, 72% 53.74%, 73% 55.33%, 74% 56.79%, 75% 58.09%, 76% 59.18%, 77% 60.05%, 78% 60.67%, 79% 61.02%, 80% 61.11%, 81% 60.91%, 82% 60.45%, 83% 59.73%, 84% 58.76%, 85% 57.58%, 86% 56.22%, 87% 54.70%, 88% 53.06%, 89% 51.35%, 90% 49.60%, 91% 47.86%, 92% 46.18%, 93% 44.59%, 94% 43.14%, 95% 41.85%, 96% 40.77%, 97% 39.91%, 98% 39.31%, 99% 38.97%, 100% 38.90%);
    width: 101%;
    height: 100px;
    background-color: var(--bg-dark-comb);
    position: absolute;
    top: -1px;
    z-index: 999;
  }
  
  @keyframes float {
    0% {
      transform: translatey(0px);
      -webkit-transform: translatey(0px);
      -moz-transform: translatey(0px);
      -ms-transform: translatey(0px);
      -o-transform: translatey(0px);
    }
    50% {
      transform: translatey(-15px);
      -webkit-transform: translatey(-15px);
      -moz-transform: translatey(-15px);
      -ms-transform: translatey(-15px);
      -o-transform: translatey(-15px);
    }
    100% {
      transform: translatey(0px);
      -webkit-transform: translatey(0px);
      -moz-transform: translatey(0px);
      -ms-transform: translatey(0px);
      -o-transform: translatey(0px);
    }
  }
  
  @keyframes float-cloud {
    0% {
      transform: translatey(0px);
      -webkit-transform: translatey(0px);
      -moz-transform: translatey(0px);
      -ms-transform: translatey(0px);
      -o-transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
      -webkit-transform: translatey(-10px);
      -moz-transform: translatey(-10px);
      -ms-transform: translatey(-10px);
      -o-transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
      -webkit-transform: translatey(0px);
      -moz-transform: translatey(0px);
      -ms-transform: translatey(0px);
      -o-transform: translatey(0px);
    }
  }
  
  /* BUTTON */
  .box-link {
    vertical-align: baseline;
    position: relative;
    max-width: 242px;
    margin-top: 52px;
  }
  
  .button {
    cursor: pointer;
    background: transparent;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 11px;
  }
  
  a:hover {
    text-decoration: none !important;
  }
  
  .home__explore[data-v-4162245a] {
    z-index: 2;
    pointer-events: auto;
  }
  
  .container .navbar.navbar-expand-lg.fixed-top .container .nav-link.selected {
    color: var(--yellow);
  }
  
  /* ABOUT */
  
  section.about {
    background-color: #fff;
  }
  
  section.about .container {
    z-index: 1;
  }

  section.about .container .sections {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  section.about .container .info-box {
    background-color: var(--bg-dark-comb);
    color: var(--gray-light);
    border-radius: 16px;
    padding: 30px;
    position: relative;
    display: flex;
    overflow: hidden;
    margin-bottom: 40px;
    min-height: 230px;
    margin-right: 20px;
  }

  section.about .container .info-box .tag {
    background: var(--yellow-light);
    color: black;
    padding: 5px;
    border-radius: 10px;
    line-height: 2.2;
  }

  section.about .container .info-box .text h4 {
    font-weight: bold;
    color: var(--yellow);
  }

  section.about .container .info-box .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
    mask-image: gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
    width: 50%;
    height: 60%;
    position: absolute;
    bottom: 0;
    opacity: 0.3;
    z-index: 0;
    right: -43px;
  }

  section.about .container .info-box .text {
    z-index: 1;
  }

  section.about .container .info .frame {
    width: 400px;
    top: -50px;
    left: -240px;
    height: 510px;
    position: absolute;
  }

  section.about .container .info h4 {
    font-weight: bold;
    color: var(--gray-light);
  }

  section.about .ellipse {
    background: white;
    height: 100px;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: -97px;
    clip-path: polygon(100% 0%, 0% 0% , 0% 0.00%, 1% 0.03%, 2% 0.11%, 3% 0.26%, 4% 0.46%, 5% 0.72%, 6% 1.03%, 7% 1.40%, 8% 1.83%, 9% 2.31%, 10% 2.84%, 11% 3.43%, 12% 4.07%, 13% 4.77%, 14% 5.51%, 15% 6.31%, 16% 7.15%, 17% 8.05%, 18% 8.99%, 19% 9.97%, 20% 11.01%, 21% 12.08%, 22% 13.20%, 23% 14.35%, 24% 15.55%, 25% 16.78%, 26% 18.05%, 27% 19.36%, 28% 20.70%, 29% 22.06%, 30% 23.46%, 31% 24.89%, 32% 26.34%, 33% 27.81%, 34% 29.31%, 35% 30.83%, 36% 32.36%, 37% 33.91%, 38% 35.48%, 39% 37.05%, 40% 38.64%, 41% 40.24%, 42% 41.84%, 43% 43.44%, 44% 45.05%, 45% 46.66%, 46% 48.26%, 47% 49.86%, 48% 51.46%, 49% 53.05%, 50% 54.62%, 51% 56.19%, 52% 57.74%, 53% 59.27%, 54% 60.79%, 55% 62.28%, 56% 63.76%, 57% 65.21%, 58% 66.63%, 59% 68.03%, 60% 69.39%, 61% 70.73%, 62% 72.03%, 63% 73.30%, 64% 74.54%, 65% 75.73%, 66% 76.89%, 67% 78.00%, 68% 79.08%, 69% 80.11%, 70% 81.09%, 71% 82.03%, 72% 82.92%, 73% 83.76%, 74% 84.56%, 75% 85.30%, 76% 86.00%, 77% 86.64%, 78% 87.22%, 79% 87.76%, 80% 88.23%, 81% 88.66%, 82% 89.03%, 83% 89.34%, 84% 89.59%, 85% 89.79%, 86% 89.93%, 87% 90.02%, 88% 90.04%, 89% 90.01%, 90% 89.92%, 91% 89.78%, 92% 89.58%, 93% 89.32%, 94% 89.00%, 95% 88.63%, 96% 88.20%, 97% 87.72%, 98% 87.18%, 99% 86.59%, 100% 85.95%);
  }

  /* OFFER */

  section.services {
    background-color: var(--gray-light);
  }

  section.services .container .content {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  section.services .container .content .wrapper {
    width: 500px;
    background: var(--bg-dark-comb);
    padding: 20px;
    border-radius: 16px;
    margin-right: 100px;
    position: relative;
  }
  
  section.services .container .content .wrapper::before{
    content: '';
    position: absolute;
    top: -1.15rem;
    left: -1.15rem;
    bottom: auto;
    right: auto;
    background-color: var(--yellow);
    height: 95%;
    border-radius: 16px 10px 10px 10px;
    width: 95%;
    display: block;
    z-index: 0;
  }

  section.services .container .content .wrapper .react-multi-carousel-list.image-carousel {
    width: 100%;
    height: 100%;
  }
 
  section.services .container .content .react-multi-carousel-list.image-carousel .item {
    height: 430px;
  }

  section.services .container .content .react-multi-carousel-list.image-carousel .item .image {
    position: absolute;
    border-radius: 16px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  section.services .container .content .react-multi-carousel-list.image-carousel ul li {
    padding-right: 10px;
    min-width: 430px;
  }

  section.services .container .content .activities .activity {
    background-color: var(--yellow);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    min-width: 283px;
    display: flex;
    align-items: center;
    background: linear-gradient(320deg, #2083ff, rgba(92, 235, 52, 0)), linear-gradient(180deg, #4cccff 0%, #188ebd 100%);
  }

  section.services .container .content .activities .activity.special {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  section.services .container .content .activities .activity.special img {
    position: absolute;
    width: 60px;
    left: -15px;
    bottom: -3px;
  }

  section.services .container .content .activities .activity.end {
    margin-left: 50px;
  }

  section.services .container .content .activities .activity.start {
    justify-content: flex-start;
  }

  section.services .container .content .activities .activity.end {
    justify-content: flex-end;
  }

  section.services .container .content .activities .activity .icon {
    border-radius: 10px;
    background-color: var(--gray);
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  section.services .container .content .activities .activity .icon.right {
    margin-left: 10px;
  }

  section.services .container .content .activities .activity .icon.left {
    margin-right: 10px;
  }

  section.services .wave {
    background: var(--bg-dark-comb);
    height: 100px;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: -99px;
    clip-path: polygon(100% 0%, 0% 0% , 0% 0.00%, 1% 0.03%, 2% 0.11%, 3% 0.26%, 4% 0.46%, 5% 0.72%, 6% 1.03%, 7% 1.40%, 8% 1.83%, 9% 2.31%, 10% 2.84%, 11% 3.43%, 12% 4.07%, 13% 4.77%, 14% 5.51%, 15% 6.31%, 16% 7.15%, 17% 8.05%, 18% 8.99%, 19% 9.97%, 20% 11.01%, 21% 12.08%, 22% 13.20%, 23% 14.35%, 24% 15.55%, 25% 16.78%, 26% 18.05%, 27% 19.36%, 28% 20.70%, 29% 22.06%, 30% 23.46%, 31% 24.89%, 32% 26.34%, 33% 27.81%, 34% 29.31%, 35% 30.83%, 36% 32.36%, 37% 33.91%, 38% 35.48%, 39% 37.05%, 40% 38.64%, 41% 40.24%, 42% 41.84%, 43% 43.44%, 44% 45.05%, 45% 46.66%, 46% 48.26%, 47% 49.86%, 48% 51.46%, 49% 53.05%, 50% 54.62%, 51% 56.19%, 52% 57.74%, 53% 59.27%, 54% 60.79%, 55% 62.28%, 56% 63.76%, 57% 65.21%, 58% 66.63%, 59% 68.03%, 60% 69.39%, 61% 70.73%, 62% 72.03%, 63% 73.30%, 64% 74.54%, 65% 75.73%, 66% 76.89%, 67% 78.00%, 68% 79.08%, 69% 80.11%, 70% 81.09%, 71% 82.03%, 72% 82.92%, 73% 83.76%, 74% 84.56%, 75% 85.30%, 76% 86.00%, 77% 86.64%, 78% 87.22%, 79% 87.76%, 80% 88.23%, 81% 88.66%, 82% 89.03%, 83% 89.34%, 84% 89.59%, 85% 89.79%, 86% 89.93%, 87% 90.02%, 88% 90.04%, 89% 90.01%, 90% 89.92%, 91% 89.78%, 92% 89.58%, 93% 89.32%, 94% 89.00%, 95% 88.63%, 96% 88.20%, 97% 87.72%, 98% 87.18%, 99% 86.59%, 100% 85.95%);
  }
  
  section:not(.hero) .container .title {
    margin-top: 1.5rem;
    margin-bottom: 5.2rem;
    text-align: start;
  }

  section:not(.hero) .container .title .line {
    width: 50px;
    height: 5px;
    background: var(--yellow);
    /* background: linear-gradient( 267.91deg, var(--yellow) 9.35%, var(--red) 96.48% ); */
  }
  
  section:not(.hero) .container .title h2 {
    font-weight: 800;
    font-size: 3rem;
    color: var(--corporate-blue);
  }
  
  section:not(.hero) .container .title h3 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #9e9e9e;
    padding-left: 3px;
  }
  
  .section .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .section .content:nth-child(2) {
    flex-direction: row-reverse;
  }
  
  .section .content .image {
    background-size: contain;
    height: 350px;
    background-repeat: no-repeat;
    width: 20%;
  }
  
  .section .content .info {
    width: 80%;
  }
  
  .section .content .info span {
    font-size: 60px;
    font-weight: 300;
  }
  .section .content .info .subtitles {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 150px;
  }
  
  .section .content .info .subtitles span {
    font-size: 30px !important;
    font-weight: 0 !important;
  }
  
  /* CONTACT */
  
  section.contact {
    background-color: white;
  }

  section.contact .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  section.contact .container .form {
    color: var(--bg-dark-comb);
  }

  section.contact .container .form .title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 25px;
  }

  section.contact .container .form .title svg {
    margin-right: 10px;
  }

  section.contact .container .form .title span.bold {
    font-weight: bold;
    color: var(--yellow)
  }

  section.contact .container .form .success-alert {
    font-family: 'Nunito Sans', sans-serif;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  section.contact .container .form .success-alert.show {
    display: block;
  }

  section.contact .container .form .success-alert.hide {
    display: none;
  }

  section.contact .container .contact-form .form-group {
    display: flex;
    align-items: center;
  }

  section.contact .container .contact-form .form-group label {
    margin-right: 10px;
    margin-bottom: 0;
    min-width: 70px;
  }

  section.contact .container .contact-form .form-group input {
    height: 40px;
    min-width: 250px;
    padding: 10px 12px 10px;
  }

  section.contact .container .contact-form .form-group textarea {
    min-height: 100px;
    padding: 10px 12px 10px;
  }

  section.contact .container .contact-form button.submit {
    text-transform: none;
    font-family: 'DM Sans';
    background-color: var(--yellow-light);
    color: var(--bg-dark-comb);
    font-weight: bold;
    float: right;
    margin-top: 10px;
  }

  section.contact .container .contact-form button.submit:hover {
    background-color: var(--yellow);
  }

  section.contact .container .divider {
    border-left: 2px solid var(--gray-light);
    height: 370px;
  }

  section.contact .container .info .text {
    text-align: center;
  }

  section.contact .container .info .text h5 {
    font-weight: 700;
    color: var(--bg-dark);
  }

  section.contact .container .info .text h6 {
    font-weight: 400;
    color: var(--gray);
  }

  section.contact .container .boxes {
    /* border: 1px solid var(--gray-light); */
    display: flex;
    border-radius: 6px;
    padding: 20px;
    justify-content: center;
  }

  section.contact .container .boxes .info-wrapper {
    border: 1px solid var(--bg-dark-content);
    border-radius: 10px;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    background-color: var(--bg-dark-content);
    min-width: 180px;
  }

  section.contact .container .boxes .info-wrapper .type {
    font-weight: bold;
  }

  section.contact .container .boxes .info-wrapper .icon {
    width: 70px;
    height: 70px;
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--gray-dark);
    border-radius: 50%;
    margin-bottom: 10px;
  }

  /* FOOTER */
  
  footer {
    height: 120px;
    position: relative;
    background-color: var(--bg-dark-content);
    color: #ffff;
  }
  
  footer .container {
    height: 100%;
  }
  
  footer:before {
    content: '';
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    height: 39px;
    top: -39px;
    left: 0;
    right: 0;
  }
  
  .prefooter {
    top: 100%;
    height: 180px;
    margin-top: -17px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: top;
    pointer-events: none;
  }
  
  .footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .identity {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: -20px;
    width: 40%;
    color: #fff;
  }
  
  .identity img {
    max-width: 45px;
    margin-right: 20px;
  }
  
  .identity p {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    padding: 0;
    color: var(--gray-light);
    opacity: 0.9;
  }
  
  .identity p span {
    font-size: 11px;
    margin-right: 4px;
  }
  
  .social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: -10px;
  }
  
  .social a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    background-color: var(--gray-light);
    border-radius: 12px;
    cursor: pointer;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .social svg {
    fill: black;
  }

  .social a:hover svg {
    fill: var(--gray-light);
  }
  
  .rrss {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -25px;
  }
  
  .rrss .mail {
    height: 40px;
  }
  
  .rrss .mail a {
    color: #888888;
    font-weight: 600;
    font-size: 10px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  
  .rrss .social a div {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rrss .mail a:hover {
    color: var(--blue);
  }
  
  .social i {
    color: #808080;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    font-size: 16px;
    margin: 0 auto;
  }
  
  .social a:hover i {
    color: var(--light);
  }
  
  .social .in:hover {
    background-color: #0e76a8;
  }

  @media (min-width: 1420px) {
    .hero-image {
      margin-right: -10%;
    }
    
  }
  
  @media (min-width: 1300px) and (max-width: 1419px) {
    .hero-image {
      margin-right: -5%;
    }
  }
  
  @media (min-width: 1250px) and (max-width: 1299px) {
    .hero-image {
      margin-right: -3%;
    }
    .navbar .logo {
      width: 100px;
    }
  }
  
  @media (min-width: 1300px) {
    section.hero .hero-wrapper {
      margin: 120px 0 20px 0;
    }
  }
  
  @media (max-width: 1300px) {
    .container {
      padding: 0px 40px !important;
    }
  
  }
  
  @media (max-width: 1200px) {
    .box-title {
      max-width: 52%;
    }
    .navbar .logo {
      width: 80px;
    }
    .msg-h1.title {
      font-size: 3.4rem;
    }
    section.hero .hero-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 180px 0 110px;
    }
    .subtitle {
      font-size: 18px;
    }

    section:not(.hero) .container .title h2 {
      font-size: 2.4rem;
    }
  
    section:not(.hero) .container .title h3 {
      font-size: 1.1rem;
    }
  
    section.contact .mail-wrapper h3 {
      font-size: 1.8rem;
    }
    section.contact .mail-wrapper h4 {
      font-size: 0.8rem;
      margin-top: 3px;
    }
    footer .identity p {
      font-size: 11px;
    }
    footer .identity p span {
      font-size: 10px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 11px;
    }
    .navbar {
      font-size: 11px;
    }
  }
  
  @media (max-width: 1180px) {
    .box-title {
      max-width: 50%;
    }
    .msg-h1.title {
      font-size: 3.2rem;
    }
  }
  
  @media (max-width: 1100px) {
    .msg-h1.title {
      font-size: 3.2rem;
    }
    .navbar-nav .nav-item {
      margin-right: 1.8rem;
    }
    section.app #counter span {
      font-size: 5.5rem;
    }
    section.app #counter {
      margin-top: 2.2rem;
    }
    section.app .app-message span.title {
      font-size: 2rem;
      margin-top: 0.5rem;
    }
    section.app .app-message span.subtitle {
      font-size: 0.9rem;
    }
    section.app .app-message {
      margin-bottom: 60px;
    }
    section.contact .mail-wrapper form {
      max-width: 440px;
    }
    section.contact .mail-wrapper form .input input {
      font-size: 14px;
      height: 51px;
    }
    section.contact .mail-wrapper form .actions .submit-btn {
      font-size: 14px;
    }
    .hero-image {
      position: absolute;
      width: 57%;
      z-index: -1;
      right: 0;
    }
  }
  
  @media (max-width: 1060px) {
    .navbar .logo-link {
      margin-right: 18%;
    }
    footer .social i {
      font-size: 14px;
    }
    footer .rrss .social a div {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    footer .social a {
      border-radius: 7px;
    }
    .box-title {
      margin-top: 0px;
    }
    .hero-image img.panel-pic {
      opacity: 0.8;
      mask-image: gradient(
        linear,
        left bottom,
        left top,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
      -webkit-mask-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
    }
    .hero-image {
      width: 70%;
      /* margin-left: 30%; */
    }
  }
  
  @media (max-width: 1024px) {
    .share .share-form form.contact {
      height: 515px;
    }
  
    .share .share-aside .content {
      top: -10%;
      bottom: 0;
      height: 300px;
      max-width: 280px;
      margin: auto;
    }
  
    .share .share-aside .content h2 {
      font-size: 2rem;
    }
  
    .share .share-form form {
      height: 420px;
    }
  
    .share .share-form form.contact {
      height: 550px;
    }
  
    .share .share-form h1 {
      font-size: 1.7rem;
    }
  
    .share .share-form h2 {
      font-size: 0.8rem;
    }
  
    .share .share-form .form-group label {
      font-size: 11px;
    }
  
    .share .share-form .form-group form input {
      font-size: 11px;
    }
  
    .share .share-form .btn {
      font-size: 11px;
      margin-top: 10px;
    }
  
    .share .share-form .footer p {
      font-size: 11px;
    }
  
    .share .share-form .footer p span {
      font-size: 11px;
    }
  
    .share .share-form .footer a {
      font-size: 11px;
    }

    .hero-image {
      width: 70%;
      margin-left: 20%;
    }
  }
  
  @media (max-width: 991px) {
    nav.fixed-top {
      padding: 20px 40px;
    }
  
    .navbar .logo {
      width: 120px;
    }
  
    .navbar .navbar-nav.collapse {
      display: none;
    }
    .navbar .navbar-collapse {
      flex-basis: 40%;
    }
    .navbar .navbar-nav.navbar-right {
      flex-direction: row;
      justify-content: flex-end;
    }
    section.hero .hero-wrapper {
      margin: 160px 0 110px;
    }
  }
  
  @media (max-width: 964px) {
    .button--arrow-and-text span {
      font-size: 9px;
    }
    .box-link {
      max-width: 200px;
      margin-top: 42px;
    }
    .button--arrow-and-text {
      height: 50px;
    }
    .button--arrow-and-text:before {
      width: 50px;
      height: 50px;
    }
    footer .rrss .mail a {
      font-size: 9px;
    }
    .msg-h1.title {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 860px) {
    section:not(.hero) .container .title h2 {
      font-size: 2.2rem;
    }
    section:not(.hero) .container .title h3 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 820px) {
    section.app #counter span {
      font-size: 4.5rem;
    }
    section.app .app-message span.title {
      font-size: 1.7rem;
      margin-top: -0.8rem;
    }
    section.app .app-message span.subtitle {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 810px) {
    .button--arrow-and-text svg {
      margin-right: 22px;
    }
    .button--arrow-and-text {
      height: 45px;
    }
    .button--arrow-and-text:before {
      width: 45px;
      height: 45px;
    }
    .button--arrow-and-text {
      padding: 0 25px 0 10px;
    }
    .box-link {
      max-width: 175px;
      margin-top: 35px;
    }
  }
  
  @media (max-width: 775px) {
    .msg-h1.title {
      font-size: 2.8rem;
      min-width: 420px;
    }
    .hero-image .overflow-layer .cloud-pic {
      top: 15px;
    }
    section:not(.hero) .container .title {
      margin-top: 1.5rem;
      margin-bottom: 4.2rem;
    }
    body .page section {
      position: relative;
      padding: 20px 0 20px;
    }
  
    section:not(.hero) .container .title {
      margin-top: 1.5rem;
      margin-bottom: 4.2rem;
    }
    .about .xef-wrapper:before {
      width: 15.05rem;
    }
    section.contact .mail-wrapper {
      margin: 90px 0 70px 0;
    }
  }
  
  @media (max-width: 710px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 10px;
    }
    .navbar {
      font-size: 10px;
    }
    .navbar .logo {
      width: 110px;
    }
    .button--arrow-and-text svg {
      margin-right: 22px;
      width: 20px;
    }
    .button--arrow-and-text {
      padding: 0 25px 0 12px;
    }
  
    .button--arrow-and-text {
      height: 42px;
    }
    .button--arrow-and-text:before {
      width: 42px;
      height: 42px;
    }
    .box-link {
      max-width: 170px;
      margin-top: 30px;
    }
    .button--arrow-and-text span {
      font-size: 9px;
      line-height: 10px;
    }
  
    .button--arrow-and-text svg {
      margin-right: 23px;
      width: 20px;
    }
  
    section:not(.hero) .container .title h2 {
      font-size: 2rem;
    }
    section:not(.hero) .container .title h3 {
      font-size: 0.9rem;
    }
    section.contact .mail-wrapper h3 {
      font-size: 1.5rem;
    }
    section.contact .mail-wrapper h4 {
      font-size: 12px;
      margin-top: 0px;
    }
    section.contact .mail-wrapper form {
      max-width: 420px;
    }
    section.contact .mail-wrapper form {
      margin-top: 25px;
    }
    footer .identity img {
      max-width: 40px;
      margin-right: 20px;
    }
    .hero-image img.panel-pic {
      opacity: 0.4;
      mask-image: gradient(
        linear,
        left bottom,
        left top,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
      -webkit-mask-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(black),
        to(rgba(0, 0, 0, 0))
      );
    }
  }
  
  @media (max-width: 690px) {
    .msg-h1.title {
      font-size: 2.7rem;
      min-width: 420px;
    }
    .hero-image .overflow-layer .food-pic {
      width: 70%;
      top: 10px;
    }
    .msg-h1.title {
      font-size: 2.6rem;
      min-width: 410px;
    }
  }
  
  @media (max-width: 670px) {
    section.app .counter-wrapper {
      padding: 20px;
    }
    section.app #counter span {
      font-size: 4rem;
      position: relative;
    }
  
    section.app #counter > span:not(:last-child) {
      margin-right: 40px;
    }
    section.app #counter span sup {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      top: unset;
      left: unset;
      bottom: 0;
      right: 10%;
      position: absolute;
    }
    section.app #counter {
      margin-top: 2.2rem;
      margin-bottom: 1.2rem;
    }
    section.app .app-message span.title {
      font-size: 1.5rem;
      margin-top: -1rem;
    }
    section.app #counter {
      margin-top: 1.6rem;
    }
    .about .xef-wrapper {
      margin-bottom: 150px;
      min-height: 420px;
    }
    .about .xef-wrapper .xef-pic {
      height: 420px;
    }
    .about .xef-wrapper:before {
      width: 12rem;
    }
    .about .db-gradient {
      margin-bottom: 90px;
    }
    .about .xef-wrapper {
      margin-bottom: 130px;
    }
  }
  
  @media (max-width: 650px) {
    .msg-h1.title {
      font-size: 2.4rem;
      min-width: 400px;
    }
  }
  
  @media (max-width: 620px) {
    section.app .app-message span.title {
      font-size: 1.5rem;
      max-width: 270px;
      line-height: 1.2;
      text-align: center;
    }
    section.app .app-message span.subtitle {
      display: none;
    }
    .wave-shape svg {
      height: 60px;
    }
    section:not(.hero) .container .title {
      margin-top: 1.5rem;
    }
  }
  
  @media (max-width: 600px) {
    .msg-h1.title {
      font-size: 2.7rem;
      min-width: 370px;
    }
    section.hero .hero-wrapper {
      margin: 130px 0 40px;
    }
    .hero-image .overflow-layer .food-pic {
      width: 75%;
      top: 10px;
    }
    .hero-image {
      width: 80%;
      margin-right: 20%;
    }
    .hero-image .overflow-layer .food-pic {
      width: 65%;
      top: 20px;
    }
    .about .xef-wrapper .xef-pic {
      height: 380px;
    }
    .about .xef-wrapper {
      min-height: 380px;
    }
    .subtitle {
      font-size: 14px;
    }
    section:not(.hero) .container .title h2 {
      font-size: 1.8rem;
    }
    .about .xef-content .xef-name span.name {
      font-size: 1.2em;
    }
    .about .xef-content .xef-name span.position {
      font-size: 12px;
    }
    .about .xef-content .xef-main h3 {
      font-size: 1.5rem;
    }
    .about .btn span {
      font-size: 10px;
    }
    .about .btn {
      width: 125px;
    }
    .about .btn {
      padding: 0.5rem 1.9rem;
    }
    .about .db-gradient .db-wrapper h3 {
      font-size: 1.6em;
    }
    section.app #counter span {
      font-size: 3rem;
    }
    section.app .app-message {
      margin-top: -12px;
    }
  
    /* SHARE */
    .share .share-aside img.texting {
      width: 100px;
      right: 0;
    }
  }
  
  @media (max-width: 580px) {
    .about .xef-wrapper:before {
      width: 7rem;
    }
    footer .identity {
      width: 200px;
    }
    footer .social a {
      margin-left: 20px;
    }
  }
  
  @media (max-width: 569px) {
    .about .xef-wrapper:before {
      width: 7rem;
    }
  }
  
  @media (max-width: 580px) {
    .msg-h1.title {
      font-size: 2.5rem;
      min-width: 340px;
    }
  }
  
  @media (max-width: 520px) {
    section:not(.hero) .container .title h2 {
      font-size: 1.5rem;
    }
    section:not(.hero) .container .title h3 {
      font-size: 0.8rem;
    }
    section:not(.hero) .container .title {
      margin-bottom: 3.2rem;
    }
    section.contact .mail-wrapper h3 {
      font-size: 1.4rem;
    }
    section.contact .mail-wrapper form {
      max-width: 370px;
    }
  }
  
  @media (max-width: 500px) {
    .msg-h1.title {
      font-size: 38px;
      min-width: 300px;
    }
    .hero-image {
      top: 130px;
      width: 350px;
      margin-right: -5px;
    }
    .box-link {
      max-width: 158px;
      margin-top: 30px;
    }
    section.app .counter-wrapper {
      padding: 5px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 9px;
    }
    .navbar {
      font-size: 9px;
    }
    .navbar .logo {
      width: 60px;
    }
    section.hero .hero-wrapper .triangle {
      width: 530px;
      height: 300px;
    }
    section.contact .mail-wrapper h3 {
      font-size: 1.3rem;
    }
    .container {
      padding: 0px 20px;
    }
    nav.fixed-top {
      padding: 20px;
    }
    section.about .container .info-box {
      width: 100%;
      min-width: 330px;
    }
    section.about .container .info-box p {
      font-size: 1.1rem;
    }
    section.about .container .sections {
      max-width: 100%;
    }
    section.services .container .content .wrapper {
      width: 300px;
    }
    section.services .container .content .react-multi-carousel-list.image-carousel ul li {
      min-width: 230px;
    }
    section.services .container .content .react-multi-carousel-list.image-carousel .item {
      height: 230px;
    }
    section.services .container .content .activities .activity {
      margin-bottom: 0;
    }
    section.contact .container .info .text h5 {
      font-size: 1rem;
    }
    section.contact .container .info .text h6 {
      font-size: 0.8rem;
    }
    section.contact .container .boxes {
      flex-wrap: wrap;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    section.contact .container .boxes .info-wrapper {
      margin-right: 0;
      margin-bottom: 5px;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
    }
    section.contact .container .boxes .info-wrapper span.type {
      display: none;
    }
    section.contact .container .boxes .info-wrapper .icon {
      width: 40px;
      height: 40px;
      margin-bottom: 0;
    }
    section.contact .container .form {
      margin-bottom: 40px;
    }
  }
  
  @media (max-width: 480px) {
    footer {
      height: 100%;
    }
    /* footer .footer-wrapper {
      flex-direction: column-reverse;
    } */
    footer .identity {
      width: 200px;
      margin-top: 10px;
    }
    footer .rrss {
      margin-top: 0px;
      align-items: flex-end;
    }
    footer .social {
      margin-bottom: 10px;
    }
    footer .social a:first-child {
      margin-left: 0px;
    }
    footer .rrss .mail a {
      font-size: 10px;
      font-weight: 400;
    }
    footer .identity img {
      max-width: 30px;
    }
  }
  
  @media (max-width: 450px) {
    .box-title {
      max-width: 330px;
    }
  }
  
  @media (max-width: 440px) {
    .msg-h1.title {
      min-width: 80%;
      font-size: 30px;
    }
  }
  
  @media (max-width: 420px) {
    .share .rrss .social i {
      font-size: 22px;
    }
    .share .rrss .social a div {
      width: 40px;
      height: 40px;
    }
    .share .rrss .social a {
      margin-right: 18px;
    }
  }
  
  @media (max-width: 400px) {
    .hero-image img.panel-pic {
      right: -41%;
    }
    .hero-image .overflow-layer .food-pic {
      width: 180px;
      top: 100px;
      right: 95px;
    }
    .navbar-nav.navbar-right a {
      border-radius: 8px;
    }
    section.contact .mail-wrapper h4 {
      text-align: center;
      max-width: 250px;
    }
    section.contact .mail-wrapper form .input input {
      font-size: 12px;
      height: 49px;
    }
    section.contact .mail-wrapper form {
      max-width: 300px;
    }
    section.contact .mail-wrapper form .actions .submit-btn {
      font-size: 12px;
    }
  }

  @media (max-width: 980px) {
    section.about .container .sections {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    section.about .container .sections .info-box {
      min-height: unset;
      margin-right: 0;
    }

    section.about .container .info-box .image {
      height: 60%;
      width: 100%;
    }

  }

  @media (max-width: 990px) { 
    /* section.services .container .content .wrapper {
      width: 400px;
    }
    section.services .container .content .react-multi-carousel-list.image-carousel ul li {
      min-width: 330px;
    }
    section.services .container .content .react-multi-carousel-list.image-carousel .item {
      height: 330px;
    } */
    section.about .waves {
      top: -20px;
    }
    section.contact .container {
      flex-direction: column;
    }
    section.contact .container .divider {
      border-bottom: 2px solid var(--gray-light);
      border-left: unset;
      width: 100%;
      margin-bottom: 20px;
      height: 0;
    }
  }

  @media (max-width: 1130px){
    section.contact {
      padding-bottom: 10px !important;
    }
  }

  @media (max-width: 880px) { 
    section.services .container .content .activities .activity.special {
      padding-left: 50px;
      align-items: flex-start;
    }
  }

  @media (max-width: 773px) { 
    section.services .container .content {
      flex-direction: column;
      align-items: center;
    }

    section.services .container .content .activities {
      display: grid;
      grid-template-columns: repeat;
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      grid-gap: 1rem;
      grid-auto-flow: dense;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 40px;
    }

    section.services .container .content .wrapper {
      margin-right: 0;
    }
  }
  
  @media (max-width: 370px) {
    section.about .container .sections {
      font-size: 10px;
    }
  }
  
  @media (max-width: 360px) {
    .subtitle {
      font-size: 14px;
    }
  }
  
  @media (max-width: 350px) {
    section.contact .mail-wrapper h3 {
      font-size: 1.1rem;
      text-align: center;
    }
  }
  
  @media (max-width: 330px) {
    .msg-h1.title {
      font-size: 30px;
    }
    .hero-image img.panel-pic {
      right: -65%;
    }
  }